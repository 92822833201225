import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { connect } from 'react-redux';
import * as action from '@store/user/actions';
import { isPassword } from '@utils';
import { CustomButton, CustomInput, MainHeaderText, Text } from '@components';

const Verification = ({ socialLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const newState = location.state || {}
  const { regData = {} } = newState;
  const { email, otp, from, fname, lname, provider, externalId, image } = regData;

  const [state, setState] = useState({
    otp: '',
    isLoading: false,
    buttonDisable: true,
    one: '',
    two: '',
    three: '',
    four: '',
  });

  useEffect(() => {
    if (!location.state) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (
      state.one?.length > 0 &&
      state.two?.length > 0 &&
      state.three?.length > 0 &&
      state.four?.length > 0
    ) {
      setState(prev => ({ ...prev, buttonDisable: false }))
    } else {
      setState(prev => ({ ...prev, buttonDisable: true }))
    }
  }, [state.one, state.two, state.three, state.four, state.buttonDisable])

  const secureEmail = (email) => {
    let name = email?.split('@')
    let userName = name[0]?.split('')
    let domain = '@' + name[1]
    if (userName?.length > 2) {
      let times = userName.length - 2
      return userName[0] + userName[1] + '*'.repeat(times > 5 ? 5 : times) + domain
    } else if (userName?.length > 1) {
      let newArray = userName.map((item, index) => (index > 0) ? '*' : item)
      return newArray.join('') + domain
    } else {
      let newArray = userName.map(() => '*')
      return newArray.join('') + domain
    }
  }



  const onKeyUp = (elmnt, state) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next]?.focus()
      }
    }
    else {
      const next = elmnt.target.tabIndex;
      if (next < 5 && state !== '') {
        elmnt.target.form.elements[next]?.focus()
      }
    }

  }

  const onValidateOtp = () => {
    setState(prev => ({ ...prev, isLoading: true }))
    let inputOtp = state.one + state.two + state.three + state.four
    if (+inputOtp === otp) {
      if (from === 'forgetPassword') {
        navigate('/reset-password', { state: { email, otp, from } })
      } else if (from === 'signUp') {
        navigate('/sign-up-next', { state: { email, otp, from } })
      } else {
        let data = {
          email: email,
          fname: fname,
          lname: lname,
          image: image,
          provider: provider,
          externalId: externalId,
        }
        socialLogin(data).then(res => {
          if (res?.payload?.status === 200 && res?.payload?.data) {
            setState(prev => ({ ...prev, isLoading: false }))
          } else if (res?.payload?.response && res?.payload?.response?.status === 422) {
            setState(prev => ({ ...prev, isLoading: false }))
            navigate('/social-login-details', { state: data })
          } else {
            alert('Login failed with error')
          }
        })
      }
    } else {
      alert('Please enter correct otp')
      setState(prev => ({ ...prev, one: '', two: '', three: '', four: '', isLoading: false }))
    }
  }

  const Header = () => {
    return (
      <div className={`mb-10 self-start`}>
        <MainHeaderText>
          VERIFY <MainHeaderText className={'text-maroon'}>MAIL</MainHeaderText>
        </MainHeaderText>
        <div className={`my-5`}>
          <Text>{`A One Time Passcode has been sent to your ${email && secureEmail(email)}`}</Text>
        </div>
      </div>
    );
  };

  return (
    <LoginPart>
      <div />
      <LoginContent>
        <Header />
        <InputContainer>
          <Text className={'mb-[30px]'}>{'Please enter the OTP below to verify the your email address:'}</Text>
          <form onSubmit={() => alert('done')}>
            <div className='flex lg:justify-start center mb-5'>
              <div className='w-[50px] px-2'>
                <CustomInput
                  onFocus={e => e.target.select()}
                  autoComplete={'off'}
                  tabIndex={1}
                  onKeyUp={e => onKeyUp(e, state.one)}
                  type={'text'}
                  inputStyle={inputStyle}
                  maxLength={1}
                  value={state.one}
                  onChange={(event) =>
                    setState((prev) => ({ ...prev, one: event.target.value }))
                  }
                />
              </div>
              <div className='w-[50px] px-2'>
                <CustomInput
                  onFocus={e => e.target.select()}
                  autoComplete={'off'}
                  tabIndex={2}
                  onKeyUp={e => onKeyUp(e, state.two)}
                  type={'text'}
                  inputStyle={inputStyle}
                  maxLength={1}
                  value={state.two}
                  onChange={(event) =>
                    setState((prev) => ({ ...prev, two: event.target.value }))
                  }
                />
              </div>
              <div className='w-[50px] px-2'>
                <CustomInput
                  onFocus={e => e.target.select()}
                  autoComplete={'off'}
                  tabIndex={3}
                  onKeyUp={e => onKeyUp(e, state.three)}
                  type={'text'}
                  inputStyle={inputStyle}
                  maxLength={1}
                  value={state.three}
                  onChange={(event) =>
                    setState((prev) => ({ ...prev, three: event.target.value }))
                  }
                />
              </div>
              <div className='w-[50px] px-2'>
                <CustomInput
                  onFocus={e => e.target.select()}
                  autoComplete={'off'}
                  tabIndex={4}
                  onKeyUp={e => onKeyUp(e, state.four)}
                  type={'text'}
                  inputStyle={inputStyle}
                  maxLength={1}
                  value={state.four}
                  onChange={(event) =>
                    setState((prev) => ({ ...prev, four: event.target.value }))
                  }
                />
              </div>
            </div>
          </form>
          <CustomButton
            title={'Validated OTP'}
            onClick={onValidateOtp}
            className={'mt-[10px] px-4'}
            disabled={state.buttonDisable}
            loading={state.isLoading}
          />
        </InputContainer>
      </LoginContent>
      <div className={'flex items-center w-[80%] justify-center lg:justify-start'}>
        <div className={'flex items-center self-start whitespace-nowrap flex-col lg:flex-row'}>
          <CustomButton
            title={'Sign In'}
            onClick={() => navigate('/')}
            reversStyle
          />
          <div className={'ml-4 lg:ml-8 '}>
            <Text className={'overflow-hidden overflow-ellipsis'}>
              Already have an account?
            </Text>
          </div>
        </div>
      </div>
    </LoginPart>
  );
};

const inputStyle = { paddingLeft: '10px', paddingRight: '10px', textAlign: 'center' }

const LoginPart = tw.div`
flex
py-5
lg:w-full
w-4/5
lg:bg-white
lg:item-start
items-center
flex-col
justify-between`;

const LoginContent = tw.div`
lg:justify-start
lg:items-start
w-[80%]
flex 
flex-col
items-center
justify-center`;

const InputContainer = tw.div`
flex 
flex-col
w-full
lg:w-3/5
lg:justify-start
lg:items-start
justify-center 
items-center`;

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (data) => dispatch(action.registerUser(data)),
    socialLogin: (data) => dispatch(action.socialLogin(data)),
    updateUser: (data) => dispatch(action.updateUser(data)),
  };
};

export default connect(null, mapDispatchToProps)(Verification);
