import tw from 'tailwind-styled-components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import alarmIcon from '@assets/images/alarmIcon.png';
import locationIcon from '@assets/images/locationIcon.png';
import { combine, dayFromNow, logEvent } from '@utils';
import './gamesBoxStyle.css';
import { useTranslation } from 'react-i18next';

const GamesBox = ({ item, index, play, userHcp }) => {
  const {t}=useTranslation('common');
  const navigate = useNavigate();
  const { startDate, name, startTime, clubAddress } = item;
  var matchDate = dayFromNow(startDate);
  var time = moment.utc(startTime, 'HH:mm').local().format('h:mm a') || '00:00'

  const MatchesBarText = () => {
    var tournamentDate = combine(startDate, startTime)
    var newDate = combine(startDate, startTime)
    var endDate = moment(newDate).add(24, 'hours')
    let deadLineHours = `${item.registrationDeadline}`;
    let deadLineStart = moment.utc(item.startDate).local().subtract(+deadLineHours, 'hours').format('DD MMM YYYY hh:mm A')
    let registrationStart = moment.utc(item?.registrationStart).local().format()

    const checkHcp = () => {
      let hcp = userHcp
      let userType = (hcp === '0' || hcp === 0) ? 'Professional' : 'Amateur'
      let tournamentType = item?.subType
    return userType === tournamentType ? true : false
    }

    if (moment().isBetween(moment(tournamentDate), moment(endDate)) && dayFromNow(startDate) !== 'Yesterday') {
      return (
        <Btn play={play}>
          <Dot />
          <LiveText>{t('info.liveScoring')}</LiveText>
        </Btn>
      )
    } else if (dayFromNow(startDate) === 'Yesterday') {
      return (
        <Btn play={play}>
          {t('info.scoring')}
        </Btn>
      );
    } else if (moment().isBefore(registrationStart)) {
      return (
        <Btn play={play}>
          {t('info.registerStart') + moment(registrationStart).format('MMM DD, YYYY @ h:mm A')}
        </Btn>
      );
    } else if (moment().diff(deadLineStart) >=  0) {
      return (
        <Btn play={play}>
          {t('info.registerClose')}
        </Btn>
      );
    } else if (checkHcp()) {
      return (
        <Btn play={play} className='text-fbButtonColor'>
          {t('info.register')}
        </Btn>
      );
    } else {
      return (
        <Btn play={play}>
          {'For ' + item?.subType + ' Only'}
        </Btn>
      );
    }
  }

  const TournamentSubType = ({ condition }) => {
    let bgColor = condition ? 'bg-dot' : 'bg-fbButtonColor'
    return (
      <div style={{ width: '30px', minWidth: '30px', height: '30px', minHeight: '30px' }} className={`rounded-full ${bgColor} text-white center font-bold`}>
        {condition ? 'P' : 'A'}
      </div>
    );
  }
  const navigateToTournament = () => {
    navigate(`/tournament/${item.id}?fs=0`)
    logEvent('web_tournament', 'tournamentDetails', { tournamentId: item?.id })
  }
  


  return (
    <Container onClick={navigateToTournament} play={play}>
      {!play && <Date> {matchDate}</Date>}
      <OuterView play={play}>
        <Detail play={play}>
          <div className='w-full justify-between flex'>
            <Title className='text-sm md:text-base lg:text-lg xl:text-xl'>{name}</Title>
            <TournamentSubType condition={item?.subType === 'Professional' ? true : false} />
          </div>
          <GameName>{`League, ${item?.calcMode} Play`}</GameName>
          <DetailView>
            <TimeView>
              <Img src={alarmIcon} />
              <Time>{time}</Time>
            </TimeView>
            <TimeView>
              <Img src={locationIcon} />
              <Time className="address">{clubAddress}</Time>
            </TimeView>
          </DetailView>
        </Detail>
        {!play && <MatchesBarText />}
      </OuterView>
    </Container >
  )
}


const Container = tw.button`
${p => (p.play ? 'rounded-[20px] h-[300px]' : 'rounded-[18px] mt-[16px]')}
flex
flex-col
w-full
bg-white
mx-1
h-auto
p-[px]
lg:min-w-full
min-w-[300px]

`

const OuterView = tw.div`
${p => (p.play ? 'rounded-[20px] h-[220px]' : 'shadow-3xl rounded-[18px] mt-[16px] h-[220px] lg:h-auto')}
w-full
overflow:hidden
px-[7px]
`
const Date = tw.text`
date-style
mx-[18px]
`

const Title = tw.text`
text-black 
text-lg
news-title
h-[30px]
w-full
break-all
overflow-hidden
text-ellipsis
`

const Detail = tw.div`
${p => (p.play ? 'border-b-[0px]' : 'border-b-[1px]')}
text-left
py-[20px] 
px-[8px]
flex
flex-col
border-gray-100
h-full
min-h-[200px]
w-full
`

const DetailView = tw.div`
flex 
flex-col 
flex-wrap
`

const GameName = tw.text`
game-name
mt-[4px]
`
const TimeView = tw.div`
flex 
flex-row 
items-center
mt-[16px]
`
const Img = tw.img`
w-[15px] 
h-[15px] 
object-contain
`
const Time = tw.text`

time-text-style
ml-[15px]
w-[75%]
break-all
overflow-hidden
text-ellipsis
`
const Dot = tw.div`
bg-[rgb(207,48,68)]
w-[6px]
h-[6px]
rounded-full
mr-[13px]
`

const LiveText = tw.div`
font-bold
text-[12px]
`

const Btn = tw.button`
font-medium
text-[16px]
justify-center 
items-center 
h-[50px] 
w-full
flex
flex-row
`


export default GamesBox


