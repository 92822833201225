import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HeaderText, Text } from '@components/';

const WelcomeHeader = ({ club, data, userName }) => {
  const { t } = useTranslation('common');

  const Box = ({ title, value, border }) => {
    let sideLine = border && 'border-t-0 border-b-0 border border-[rgb(34,35,49/7%)]'
    return (
      <div style={{ fontSize: 'Source Sans Pro, sans-serif' }} className={`flex-1 ${sideLine}`} >
        <div className="lg:text-[19px] text-[16px] font-bold tracking-[0.19px] lg:tracking-[0.23px]">{value}</div>
        <div className='text-[12px]'>{title}</div>
      </div>
    );
  }

  return (
    <div className="lg:grid-cols-2 md:grid-cols-2 cols-1 md:p-8 p-4 md:flex md:justify-between lg:px-[10%] px-[5%]">
      <div>
        {
          club
            ? <HeaderText>{t('dashboard.goodMorning')}</HeaderText>
            : <HeaderText>{t('dashboard.welcome')} <span className='text-maroon'>{userName}</span>!</HeaderText>
        }
        <div className='flex mt-[12px] mb-[30px] w-[320px] lg:w-full'>
          <div className='w-[6px] mt-2 h-[6px] bg-fbButtonColor mr-[10px] rounded-full' />
          {
            club
              ? <Text>{t('dashboard.tournamentHasBeenApproved1DayAgo')}</Text>
              : <Text>{t('dashboard.newTournaments')}</Text>
          }
        </div>
      </div>

      <div className="score-box box-border border-2 p-3 mt-2 lg:w-[350px] lg:h-full md:w-[300px] md:h-4/5  w-11/12">
        <div className="flex space-x-4 text-center">
          <Box title={data[0].title} value={data[0].value} />
          <Box border title={data[1].title} value={data[1].value} />
          <Box title={data[2].title} value={data[2].value} />
        </div>
      </div>
    </div>
  )
}

export default WelcomeHeader;
