import React, { lazy } from 'react';

const Login = lazy(() => import('@containers/login'));
const Settings = lazy(() => import('@containers/setting'));
const Tournament = lazy(() => import('@containers/tournament'));
const PersonalInfo = lazy(() => import('@containers/personalInfo'));
const MyTournament = lazy(() => import('@containers/myTournament'));
const TournamentHistory = lazy(() => import('@containers/tournamentHistory'));
const PlayedTournament = lazy(() => import('@containers/tournament/playedTournament'));
const TournamentPayment = lazy(() => import('@containers/tournament/tournamentPayment'));

const routes = [
  { exact: true, isPublic: true, path: "/sign-up", name: 'Login', element: <Login /> },
  { exact: true, isPublic: true, path: "/sign-up-next", name: 'Login', element: <Login /> },
  { exact: true, isPublic: true, path: "/verification", name: 'Login', element: <Login /> },
  { exact: true, isPublic: true, path: "/reset-password", name: 'Login', element: <Login /> },
  { exact: true, isPublic: true, path: "/social-login-details", name: 'Login', element: <Login /> },
  { exact: true, isClub: true, path: '/my-tournament/:index/:page', name: 'my-tournament', element: <MyTournament /> },


  { exact: true, isClub: true, path: '/club-settings', name: 'Club-Settings', element: <Settings /> },
  { exact: true, isClub: true, path: '/club-settings/:tabScreen', name: 'Club-Settings', element: <Settings /> },
  { exact: true, isClub: true, path: "/club-settings/faq/:faqId", name: 'Club-Settings', element: <Settings /> },
  { exact: true, isClub: true, path: "/club-settings/faq/search/:text", name: 'Club-Settings', element: <Settings /> },
  { exact: true, isClub: true, path: "/club-settings/faq/search/:text/:faqId", name: 'Club-Settings', element: <Settings /> },

  { exact: true, path: '/settings', name: 'Settings', element: <Settings /> },
  { exact: true, path: '/settings/:tabScreen', name: 'Settings', element: <Settings /> },
  { exact: true, path: "/settings/faq/:faqId", name: 'Settings', element: <Settings /> },
  { exact: true, path: "/settings/faq/search/:text", name: 'Settings', element: <Settings /> },
  { exact: true, path: "/settings/faq/search/:text/:faqId", name: 'Settings', element: <Settings /> },

  { exact: true, path: "/personal-info", name: 'PersonalInfo', element: <PersonalInfo /> },
  { exact: true, path: "/tournament/:tournamentId", name: 'Tournament', element: <Tournament /> },
  { exact: true, path: "/tournament-history", name: 'TournamentHistory', element: <TournamentHistory /> },
  { exact: true, path: "/next-tournament/:tournamentId", name: 'PlayedTournament', element: <PlayedTournament /> },
  { exact: true, path: "/playedTournament/:tournamentId", name: 'PlayedTournament', element: <PlayedTournament /> },
  { exact: true, path: "/tournament-history/:tournamentId", name: 'PlayedTournament', element: <PlayedTournament /> },
  { exact: true, path: "/tournament-payment/:tournamentId", name: 'TournamentPayment', element: <TournamentPayment /> },
]

export default routes;