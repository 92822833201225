import tw from 'tailwind-styled-components';

const CustomCheckbox = ({ title, onChange, value, errorText, containerStyle, inputStyle, titleClass, className, check }) => {
  return (
    <div className={`${containerStyle} cursor-pointer`} onClick={onChange}>
      <input
        className={className}
        checked={check}
        type='radio'
        style={{ ...inputStyle, marginTop: '0.7vh' }}
      />
      <p className={titleClass}>{title}</p>
      {errorText && <ErrorText>{`${errorText}`}</ErrorText>}
    </div>
  );
};

const ErrorText = tw.p`
text-sm
text-red-500`

export default CustomCheckbox;
