const vs = (props) => {
  return +props
}

const customHeight = (rootIndex) => {
  return vs((Math.pow(2, rootIndex) * 108) / 2);
};

const customMargin = (rootIndex) => {
  return vs(Math.pow(2, rootIndex - 1) * 108 - 54)
};

export { customHeight, customMargin };