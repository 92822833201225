//keys
const StripePublishKey = process.env.StripePublishKey;
const StripeSecretKey = process.env.StripeSecretKey;
const XGraviteeAPIKey = "94ce95b4-219e-4360-a14f-a38cf817621a";
const API_KEY =
  "e0e6e67b234363c838fab78072b3b7927053dc308148705064bd724c473821f5";
const PROJECT_ID = "60342964a6891f0009bdcf69";

const SERVER_HOST = process.env.REACT_APP_API_URL;
const SOCKET_SERVER_HOST = process.env.REACT_APP_SOCKET_SERVER_HOST;

const STRIPE = `${SERVER_HOST}payments/sheet`;
const USER_HOST = `${SERVER_HOST}users`;
const USER_LOGIN = `${SERVER_HOST}users/login`;
const FORGET_PASS = `${SERVER_HOST}users/forgot-password`;
const EMAIL_VERIFY = `${SERVER_HOST}mail/send-otp`;
const SOCIAL_LOGIN = `${SERVER_HOST}users/social-login`;
const DELETE_ACCOUNT = `${SERVER_HOST}users/remove`;
const GET_USER = `${SERVER_HOST}users/me`;
const USER_SCORE = `${SERVER_HOST}users/player-score`;
const RESET_PASS = `${SERVER_HOST}users/reset-password`;
const REGISTRATIONS_VALIDATION = `${SERVER_HOST}registrations/validate`;
const REGISTRATION_STATE = `${SERVER_HOST}registration-states`;
const TOURNAMENTS = `${SERVER_HOST}tournaments`;
const DELETE_TOURNAMENT = `${SERVER_HOST}tournaments`;
const DRAFT_TOURNAMENT_DETAILS = `${SERVER_HOST}mongo-data-api/find-tournament`;
const MY_DRAFT_TOURNAMENT = `${SERVER_HOST}mongo-data-api/tournament-list`;
const MY_PUBLISH_TOURNAMENT = `${SERVER_HOST}tournaments`;
const SAVE_MY_TOURNAMENT = `${SERVER_HOST}mongo-data-api/insert-tournament`;
const UPDATE_MY_TOURNAMENT = `${SERVER_HOST}mongo-data-api/update-tournament`;
const PLAYED_TOURNAMENTS = `${SERVER_HOST}tournaments`;
const UPLOAD_TOURNAMENTS = `${SERVER_HOST}tournaments`;
const TOURNAMENT_PLAY_LIST = `${SERVER_HOST}tournaments/play`;
const UPLOAD_IMAGE = `${SERVER_HOST}files`;
const PLAYER_TOURNAMENT = `${SERVER_HOST}players/tournament`;
const PLAYED_PLAYER = `${SERVER_HOST}players/tournament`;
const TOURNAMENT_PRIZES = `${SERVER_HOST}prizes/tournament`;
const ADD_CREDIT_CARD = `${SERVER_HOST}credit-cards/add`;
const CREDIT_CARD_LIST = `${SERVER_HOST}credit-cards`;
const DELETE_CREDIT_CARD = `${SERVER_HOST}credit-cards/delete`;
const COUNTRIES = `${SERVER_HOST}countries`;
const TOURNAMENT_PAYMNET = `${SERVER_HOST}payments/charge`;
const TOURNAMENTS_TYPES = `${SERVER_HOST}tournament-types`;
const LEAGUE_RANKS = `${SERVER_HOST}league-ranks`;
const FACILITIES = `${SERVER_HOST}facilities`;
const CLUB = `${SERVER_HOST}clubs`;
const TRACK = `${SERVER_HOST}tracks`;
const COURSE = `${SERVER_HOST}courses`;
const ROUNDS = `${SERVER_HOST}rounds`;
const MATCHES = `${SERVER_HOST}matches`;
const MATCHESSCORES = `${SERVER_HOST}matches/tournament`;
const FULL_SCORE_BOARD = `${SERVER_HOST}tournaments/scores`;
const PLAYED_SCORES = `${SERVER_HOST}matches/tournament`;
const SCORES = `${SERVER_HOST}scores`;
const WAITING = `${SERVER_HOST}registrations/add`;
const USER_REWARD = `${SERVER_HOST}rounds/user/`;
const USER_PRIZE = `${SERVER_HOST}user-prizes/total`;
const TRANSACTION_HISTORY = `${SERVER_HOST}transactions/history`;
const APPGAIN_ID = `${SERVER_HOST}appgain-users`;
const CANCEL_PARTICIPATION = `${SERVER_HOST}players/cancel`;
const TERMS = `${SERVER_HOST}terms-policies/current`;
const USER_TERMS = `${SERVER_HOST}terms-policies/user`;
const ALL_TERMS = `${SERVER_HOST}terms-policies/all`;
const ACCEPT_TERMS = `${SERVER_HOST}terms-policy-users/accept-all`;
const FAQ = `${SERVER_HOST}faqs`;
const CHANGE_PASSWORD = `${SERVER_HOST}users/change-password`;
const ALL_PRIZE_UPLOAD = `${SERVER_HOST}prizes/upsert-all`;
const SOCKET = `${SOCKET_SERVER_HOST}socket`;

const config = {
  XGraviteeAPIKey,
  StripePublishKey,
  StripeSecretKey,
  PROJECT_ID,
  API_KEY,
  SERVER_HOST,
  USER_HOST,
  USER_LOGIN,
  DELETE_ACCOUNT,
  EMAIL_VERIFY,
  FORGET_PASS,
  SOCIAL_LOGIN,
  GET_USER,
  RESET_PASS,
  TOURNAMENTS,
  DELETE_TOURNAMENT,
  MY_DRAFT_TOURNAMENT,
  MY_PUBLISH_TOURNAMENT,
  PLAYER_TOURNAMENT,
  PLAYED_TOURNAMENTS,
  TOURNAMENT_PLAY_LIST,
  REGISTRATIONS_VALIDATION,
  REGISTRATION_STATE,
  UPLOAD_IMAGE,
  PLAYED_PLAYER,
  TOURNAMENT_PRIZES,
  ADD_CREDIT_CARD,
  CREDIT_CARD_LIST,
  DELETE_CREDIT_CARD,
  COUNTRIES,
  TOURNAMENT_PAYMNET,
  TOURNAMENTS_TYPES,
  LEAGUE_RANKS,
  FACILITIES,
  CLUB,
  TRACK,
  COURSE,
  ROUNDS,
  MATCHES,
  MATCHESSCORES,
  FULL_SCORE_BOARD,
  PLAYED_SCORES,
  SCORES,
  STRIPE,
  WAITING,
  USER_REWARD,
  USER_PRIZE,
  TRANSACTION_HISTORY,
  APPGAIN_ID,
  CANCEL_PARTICIPATION,
  TERMS,
  USER_TERMS,
  ALL_TERMS,
  ACCEPT_TERMS,
  FAQ,
  CHANGE_PASSWORD,
  USER_SCORE,
  SAVE_MY_TOURNAMENT,
  UPDATE_MY_TOURNAMENT,
  UPLOAD_TOURNAMENTS,
  MY_DRAFT_TOURNAMENT,
  DRAFT_TOURNAMENT_DETAILS,
  ALL_PRIZE_UPLOAD,
  SOCKET,
};

export default config;
