import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { connect } from "react-redux";
import { isEmail, isPassword, logEvent } from "@utils";
import {
  CustomButton,
  CustomInput,
  MainHeaderText,
  Text,
  Loader,
} from "@components";
import * as action from "@store/user/actions";
import GoogleImage from "@assets/images/google_webapp.png";
import FacebookImage from "@assets/images/facebook_webapp.png";
import AppleImage from "@assets/images/apple_webapp.png";
import { onPressGoogle, onPressFacebook, onPressApple } from "./widgets";

const LoginWidget = ({
  login,
  getUserData,
  getCountryList,
  forgetPass,
  socialLogin,
}) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    errorText: "",
    isLoading: false,
    fullLoading: false,
    buttonDisable: true,
  });

  useEffect(() => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(state.email) === true && state.password.length >= 8) {
      setState((prev) => ({
        ...prev,
        buttonDisable: false,
      }));
    } else if (reg.test(state.email) === true) {
      setState((prev) => ({
        ...prev,
        buttonDisable: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        buttonDisable: true,
      }));
    }
  }, [state.buttonDisable, state.email, state.password]);

  const onLogin = () => {
    setState((prev) => ({ ...prev, isLoading: true }));
    let data = {
      email: state.email.toLowerCase(),
      password: state.password,
    };
    login(data).then((res) => {
      if (res?.payload?.data && res?.payload?.status === 200) {
        getUserData();
        getCountryList();
        logEvent("web_user", "loginWithEmail", {
          id: res?.payload?.data?.id,
          email: res?.payload?.data?.email,
        });
        setState((prev) => ({ ...prev, isLoading: false }));
      } else {
        if (
          res.payload?.response?.status === 401 ||
          res?.payload?.response?.statusText === "Unauthorized"
        ) {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            errorText: "Wrong credentials!",
          }));
        } else {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            errorText: "Invalid credentials",
          }));
        }
      }
    });
  };

  let isValidateEmail = state.email === "" ? true : isEmail(state.email);
  let isValidatePass =
    state.password === "" ? true : isPassword(state.password);

  const onForgetPassword = () => {
    setState((prev) => ({ ...prev, fullLoading: true }));
    const data = { email: state.email.toLowerCase() };
    forgetPass(data).then((response) => {
      if (
        response.payload &&
        response.payload.data &&
        response.payload.status === 200
      ) {
        setState((prev) => ({ ...prev, fullLoading: false }));
        const regData = {
          email: state.email.toLowerCase(),
          otp: response.payload.data.otp,
          from: "forgetPassword",
        };
        navigate("/verification", { state: { regData } });
      } else if (response?.payload?.response?.status === 404) {
        setState((prev) => ({
          ...prev,
          fullLoading: false,
          errorText: "This email address is not registered",
        }));
      } else {
        setState((prev) => ({
          ...prev,
          fullLoading: false,
          errorText: "Internal server error. Please try again later.",
        }));
      }
    });
  };

  const onSocialLogin = (data) => {
    socialLogin(data).then((res) => {
      if (res?.payload?.status === 200 && res?.payload?.data) {
      } else if (
        res?.payload?.response &&
        res?.payload?.response?.status === 422
      ) {
        logEvent("web_user", "userLoginWithSocial", {
          id: res?.user?.data?.id,
          email: res?.user?.data?.email,
        });
        navigate("/social-login-details", { state: data });
      } else {
        alert("Internal server error. Please try again later.");
      }
    });
  };

  const onGoogle = () => {
    onPressGoogle().then((res) => {
      if (res?.uid) {
        let nameArray = res?.displayName?.split(" ");
        let fname = nameArray[0];
        let lname = nameArray[1];
        let data = {
          provider: "google",
          externalId: res?.uid,
          fname: fname,
          lname: lname,
          image: res?.photoURL,
          email: res?.email,
        };
        onSocialLogin(data);
      }
    });
  };

  const onFacebook = () => {
    onPressFacebook().then((res) => {
      if (res?.uid) {
        let nameArray = res?.displayName?.split(" ");
        let fname = nameArray[0];
        let lname = nameArray[1];
        let data = {
          provider: "facebook",
          externalId: res?.uid,
          fname: fname,
          lname: lname,
          image: res?.photoURL,
          email: res?.email,
        };
        onSocialLogin(data);
      }
    });
  };

  const onApple = () => {
    onPressApple().then((res) => {
      if (res?.uid) {
        let nameArray = res?.displayName?.split(" ");
        let fname = nameArray && nameArray[0];
        let lname = nameArray && nameArray[1];
        let data = {
          provider: "apple",
          externalId: res?.uid,
          fname: fname,
          lname: lname,
          image: res?.photoURL,
          email: res?.email,
        };
        onSocialLogin(data);
      }
    });
  };

  const navigate = useNavigate();
  const Header = () => {
    return (
      <div className={`mb-10 self-start`}>
        <MainHeaderText>
          SIGN IN TO{" "}
          <MainHeaderText className={"text-maroon"}>S6M</MainHeaderText>
        </MainHeaderText>
        <div className={`my-5`}>
          <Text>Enter your details below</Text>
        </div>
      </div>
    );
  };

  const SocialLoginContainer = () => {
    return (
      <div className={"w-full p-10 center flex-col"}>
        <Text style={{ fontSize: "15px", color: "black", opacity: "0.6" }}>
          {" "}
          or Continue with
        </Text>
        <div className="flex select-none mt-5">
          <div className="p-2">
            <SocialIcon onClick={onFacebook} src={FacebookImage} />
          </div>
          <div className="p-2">
            <SocialIcon onClick={onApple} src={AppleImage} />
          </div>
          <div className="p-2">
            <SocialIcon onClick={onGoogle} src={GoogleImage} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <LoginPart>
      <div />
      <LoginContent>
        <Header />
        <InputContainer>
          <Input>
            <CustomInput
              title={"Email address"}
              placeholder={"Your email"}
              type={"email"}
              required
              errorText={
                isValidateEmail
                  ? state.errorText
                    ? state.errorText
                    : null
                  : "Enter a valid email."
              }
              onChange={(event) =>
                setState((prev) => ({
                  ...prev,
                  email: event.target.value,
                  errorText: "",
                }))
              }
            />
          </Input>
          <Input>
            <CustomInput
              title={"Password"}
              placeholder={"Password here"}
              type={"password"}
              required
              errorText={isValidatePass ? null : "Enter a valid password."}
              onChange={(event) =>
                setState((prev) => ({
                  ...prev,
                  password: event.target.value,
                  errorText: "",
                }))
              }
              onKeyUp={(e) => (e.key === "Enter" ? onLogin() : null)}
            />
            {isValidateEmail && state.email !== "" && (
              <div className="flex justify-end my-2">
                <div
                  onClick={() => onForgetPassword()}
                  className={`text-fbButtonColor underline cursor-pointer`}
                >
                  <Text>Forget Password?</Text>
                </div>
              </div>
            )}
          </Input>
          <CustomButton
            disabled={state.buttonDisable}
            title={"Sign In"}
            onClick={() => onLogin()}
            className={"mt-[10px] px-4"}
            loading={state.isLoading}
          />
          <SocialLoginContainer />
        </InputContainer>
      </LoginContent>
      <div
        className={"flex items-center w-[80%] justify-center lg:justify-start"}
      >
        <div
          className={
            "flex items-center self-start whitespace-nowrap flex-col lg:flex-row"
          }
        >
          <CustomButton
            title={"Get Started"}
            onClick={() => navigate("/sign-up")}
            reversStyle
          />
          <div className={"lg:ml-8"}>
            <Text className={"overflow-hidden overflow-ellipsis"}>
              Don't have an account?
            </Text>
          </div>
        </div>
      </div>

      {state.fullLoading && (
        <div className="w-screen h-screen absolute top-0 left-0 bg-white/90 z-50">
          <Loader />
        </div>
      )}
    </LoginPart>
  );
};

const Input = tw.div`
flex 
flex-col
w-full
py-2`;

const LoginPart = tw.div`
flex
py-5
lg:w-full
w-4/5
lg:bg-white
lg:item-start
items-center
flex-col
justify-between`;

const LoginContent = tw.div`
lg:justify-start
lg:items-start
w-[80%]
flex 
flex-col
items-center
justify-center`;

const InputContainer = tw.div`
flex 
flex-col
w-full
lg:w-3/5
lg:justify-start
lg:items-start
justify-center 
items-center`;

const SocialIcon = tw.img`
cursor-pointer
min-w-10
max-w-10
min-h-10
max-h-10`;

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(action.login(data)),
    getUserData: () => dispatch(action.getUserData()),
    socialLogin: (data) => dispatch(action.socialLogin(data)),
    getCountryList: () => dispatch(action.getCountries()),
    forgetPass: (data) => dispatch(action.forgetPass(data)),
  };
};

export default connect(null, mapDispatchToProps)(LoginWidget);
