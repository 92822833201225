import React from 'react';
import moment from 'moment';
import { Text } from '@components/';
import CalenderDark from '@assets/images/calendarIcon.png';
import LocationDark from '@assets/images/locationIcon.png';
import tw from 'tailwind-styled-components/';
import { useTranslation } from 'react-i18next';

const PastTournamentCard = (props) => {
  const {t}=useTranslation('common');
  const {
    won,
    yourPlace,
    onClick,
    item
  } = props;

  const { name, calcMode, startDate, clubAddress, leagueRank } = item

  const Details = ({ src, title }) => {
    return (
      <div className="flex w-full items-center mt-[20px]">
        <img alt='details' src={src} className="w-[15px] h-[15px] mr-[15px] object-contain" />
        <TextContent className={'whitespace-pre-line items-start'}>{title}</TextContent>
      </div>
    );
  };

  const WonCard = ({ won, place }) => {
    const winColor = won ? 'bg-won' : 'bg-lose'
    return (
      <div className='flex items-center mt-[16px]'>
        <div className={` ${winColor} rounded-[7px] uppercase text-white font-bold text-[11px] px-[8px] py-[6px]`} style={{ fontFamily: 'Source Sans Pro, sans-serif' }}>
          {won ? t('score.youWon') : t('score.youLose')}
        </div>
        {place && <Text className='ml-[11px] text-gray51'>{place}</Text>}
      </div>
    );
  }

  const More = () => {
    return (
      <div className='uppercase font-bold text-[12px] text-center pt-[17px] border-t-[1px] mt-[20px] border-[gray151]'>
        {t('score.more')}
      </div>
    );
  }

  return (
    <div onClick={onClick} className={`snap-scroll cursor-pointer min-w-[90%] md:min-w-[320px] min-h-[257px] rounded-[18px] mt-[25px] border-[1px] border-[rgb(222, 227, 236)] p-[20px] px-[25px] select-none drop-shadow bg-[white]`} >
      <p className='font-bold text-[19px] tracking-[0.23px]'>{name}</p>
      <p className='text-[14px] tracking-[0.15px] leading-[23px] text-black/60 capitalize'>{`${leagueRank?.name}/${calcMode} Play`}</p>
      <Details src={CalenderDark} title={`${moment(startDate).format('DD MMMM YYYY')}`} />
      <Details src={LocationDark} title={`${clubAddress}`} />
      <WonCard won={won} place={yourPlace} />
      <More />
    </div >
  )
}

const TextContent = tw.text`
textTourCard
whitespace-pre-line 
items-start
`;

export default PastTournamentCard
