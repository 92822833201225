import React, { useState } from 'react';
import tw from 'tailwind-styled-components';
import { connect } from 'react-redux';
import { Text } from '@components/'
import { animated, useSpring } from '@react-spring/web';
import * as action from '@store/user/actions';

const CustomModal = ({ isOpenModal, closeModal, terms, privacy, termsApi, acceptTerms, userId, userCountry }) => {

  const [state, setState] = useState({
    screen: 'tc',
    btnLoad: false,
  })


  const hidden = !isOpenModal ? 'hidden' : 'flex'
  const modalStyle = useSpring({ scale: isOpenModal ? 1 : 0, opacity: isOpenModal ? 1 : 0 })
  const modalBackground = useSpring({ backgroundColor: isOpenModal ? 'rgba(0,0,0,0.2)' : 'transparent' })
  const active = (props) => state.screen === props ? true : false
  const headStyle = (props) => `flex cursor-pointer items-center justify-center mb-4 font-bold ${active(props) ? 'border-b-fbButtonColor border-b-[3px]' : 'border-b-[3px] border-transparent'}`

  const data = state.screen === 'tc' ? terms?.currentTermsPolicy?.contents : privacy?.currentTermsPolicy?.contents

  const bareData = data?.filter(item => item?.lang === userCountry?.language)[0]

  const createMarkup = () => {
    return { __html: state.screen === 'tc' ? bareData?.content : bareData?.content };
  }

  const accepted = () => {
    setState(prev => ({ ...prev, btnLoad: true }))
    let data = termsApi.map((item) => { return { ...item, userId: userId } })
    acceptTerms(data).then(res => {
      if (res?.payload?.data == '' && res?.payload?.status == 204) {
        setState((prev) => ({ ...prev, btnLoad: false }));
        closeModal()
      } else {
        setState((prev) => ({ ...prev, btnLoad: false }));
      }
    })
  }

  const Loader = () => {
    return (
      <div className='w-full h-full center'>
        <div className='w-[24px] h-[24px] rounded-full border-4 border-white/20 border-t-white animate-spin' />
      </div >
    );
  }

  return (
    <animated.div style={modalBackground} className={`${hidden} fixed top-0 left-0 right-0 bottom-0 z-[1000]`}>
      <div className='top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] absolute 
      lg:w-[1000px]
      lg:h-[600px]
      md:w-[600px]
      w-[95%]
      '>
        <animated.div style={modalStyle}>
          <div className={Container}>
            <div className='flex justify-evenly mt-5'>
              <PrimaryText style={{ transition: '.3s', margin: '0' }} onClick={() => setState(prev => ({ ...prev, screen: 'tc' }))} className={headStyle('tc')}>{`Privacy & policy`}</PrimaryText>
              <PrimaryText style={{ transition: '.3s', margin: '0' }} onClick={() => setState(prev => ({ ...prev, screen: 'pp' }))} className={headStyle('pp')}>{`Terms & condition`}</PrimaryText>
            </div>
            <div className="border border-horizontalLineColor " />
            <div className="md:mx-[5%] mx-[2%]">
              <Text className="h-[380px] px-[10px] overflow-auto  custom-scroll mt-3">
                <div dangerouslySetInnerHTML={createMarkup()} />
              </Text>
            </div>
            <div className='flex justify-center md:justify-end md:mx-[5%] mx-[2%] py-5'>
              <Button onClick={() => accepted()}>
                {state.btnLoad ? <Loader /> : 'Accept'}
              </Button>
            </div>
          </div>
        </animated.div>
      </div>
    </animated.div>
  );
};
const Container = `
bg-white
shadow-3xl
rounded-[10px]
overflow-scroll
no-scrollbar
p-[16px]
`;
const PrimaryText = tw.div`
font-medium
text-fbButtonColor
md:text-[27px]
sm:text-[22px]
text-[20px]
py-[10px]
text-ellipsis
overflow-hidden
whitespace-nowrap
`;
const Button = tw.button`
w-[300px]
py-2
rounded-full
text-white
font-bold
bg-fbButtonColor
`

const mapStateToProps = state => {
  return {
    userId: state.user.id,
    userCountry: state.user.myCountry,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    acceptTerms: data => dispatch(action.acceptTerms(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);