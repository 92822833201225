const MainHeaderText = (props) => {
  return (
    <span style={{ ...MainHeaderTextStyle, ...props.style }} className={`${props.className} font-bold text-[30px] md:text-[54px] lg:text-[64px]`}>
      {props.children}
    </span>
  );
}

const HeaderText = (props) => {
  return (
    <span style={{ ...MainHeaderTextStyle, ...props.style }} className={`${props.className} font-bold text-[30px] md:text-[40px] lg:text-[44px]`}>
      {props.children}
    </span>
  );
}

const Text = (props) => {
  return (
    <div style={{ ...TextStyle, ...props.style }} className={`${props.className} text-[15px] tracking-[0.16px] leading-[23px] lg:tracking-[0.17px] lg:text-[16px]`}>
      {props.children}
    </div>
  );
}


const MainHeaderTextStyle = {
  fontFamily: 'Oswald, sans-serif',
  letterSpacing: 0.7,
}

const TextStyle = {
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 0.17
}

export {
  MainHeaderText,
  HeaderText,
  Text
}