export const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isPassword = (password) => {
  return password?.length >= 8 || password?.length === 0 ? true : false
}

export const isConfirmPassword = (password, confirmPassword) => {
  return (password.length >= 8 || password?.length === 0) && (confirmPassword?.length >= 8 || confirmPassword?.length === 0) && password === confirmPassword ? true : false
}

export const isNumber = (props) => {
  if (isNaN(props) || props?.includes(' ')) {
    return false
  } else {
    return true
  }
}

export const isValidURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}