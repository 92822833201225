import React, { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components/';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '@store/tournament/actions';
import { combine } from '@utils';
import { Loader, GamesBox } from '@components/';
import { PlusSmIcon } from '@heroicons/react/solid';
import './tournamentStyle.css';
import { useTranslation } from 'react-i18next';

const NextTournament = ({ getTournamentPlayList, nextTournament }) => {
  const {t}=useTranslation('common');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getTournamentPlayList().then(res => {
      if (res?.payload?.data && res?.payload?.status === 200) {
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }, [getTournamentPlayList])

  let date = nextTournament && nextTournament.startDate
  let time = nextTournament && nextTournament.startTime

  let tournamentDate = moment(combine(date, time))
  let endDate = moment(combine(date, time)).add(24, 'hours')

  let nextButtonText;
  var momentGet = moment(nextTournament && nextTournament.startDate).startOf('day')
  var currentDate = moment().startOf('day')
  var diffr = momentGet.diff(currentDate, 'day');
  if (moment().isBetween(tournamentDate, endDate)) {
    nextButtonText = t('tourHistory.playNow')
  } else if (diffr > 1) {
    nextButtonText = (`${t('tourHistory.in')} ${diffr} ${t('tourHistory.days')}`)
  } else if (diffr === 1) {
    nextButtonText = (`${t('tourHistory.in')} ${diffr} ${t('tourHistory.days')}`)
  } else if (diffr === 0) {
    nextButtonText = (t('tourHistory.today'))
  }


  return (
    <div className='min-w-[350px] sm:min-w-[366px] md:max-w-[366px] lg:min-w-full '>
      {nextTournament ?
        <div className="bg-grayborder241 mx-auto p-[16px] rounded-[20px] mt-[24px] flex flex-col relative max-h-[500px] h-[250px]">
          <GamesBox item={nextTournament} index={0} play={true} />
          <div className='bg-grayborder241 w-[180px] self-center h-[77px] absolute bottom-[-25px] rounded-full z-[-50]'>
          </div>
          <button onClick={() => navigate(`/next-tournament/${nextTournament?.id}`)} style={{ fontFamily: 'Source Sans, sans-serif' }} className='font-bold  text-[12px] w-[150px] h-[45px] bg-fbButtonColor text-white rounded-full z-[20] absolute bottom-[-15px] self-center uppercase'>
            {nextButtonText}
          </button>
        </div>
        :
        <EmptyComponent>
          {loading ?
            <Loader />
            : <>
              {/* <Circle>
              <PlusSmIcon className='w-[25px] h-auto' />
            </Circle> */}
              <LoadText style={{ fontFamily: 'Source Sans, sans-serif' }} >{t('tourHistory.noNextTournament')}</LoadText>
            </>
          }
        </EmptyComponent>
      }
    </div>
  )
}

const EmptyComponent = tw.div`
mt-[31px]
mx-auto
w-auto
max-w-xs
h-[200px]
border-[1px]
border-[rgba()166,166,167,0.35]
rounded-[18px]
flex
flex-col
justify-center
items-center
`

const Circle = tw.div`
w-[52px]
h-[52px]
rounded-full
bg-[rgba(166,166,167,0.12)]
flex
justify-center
items-center
`
const LoadText = tw.div`
mt-[3px]
text-[16px]
tracking-[0.19px]
leading-[24px]
`

const mapStateToProps = (state) => {
  return {
    nextTournament: state.tournament.nextTournament,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTournamentPlayList: () => dispatch(actions.getTournamentPlayList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NextTournament)