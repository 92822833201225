import tw from "tailwind-styled-components";
import { Text } from "@components";
import eye from "@assets/images/eye.png";
import eyeHide from "@assets/images/eyeHide.png";
const CustomInput = (props) => {
  return (
    <div className={`${props.containerStyle}`}>
      <Text style={{ fontSize: "15px" }}>{props.title}</Text>
      {props.multiline ? (
        <TextArea
          style={{ ...props.inputStyle, marginTop: "1vh" }}
          required={props.required ? true : false}
          {...props}
        />
      ) : props.onShowPassword ? (
        <div
          className="
              border
              focus:ring-1
              border-inputBorder 
              rounded-md
              flex 
              flex-row 
              items-center 
              justify-between "
        >
          <InputEye
            style={{ ...props.inputStyle, marginTop: "1vh" ,marginBottom:'1vh' }}
            required={props.required ? true : false}
            {...props}
          ></InputEye>
          <button onClick={props.onShowPassword} style={{ paddingRight: 5 ,paddingLeft:5}}>
            <img
              alt=""
              src={props.showPassword ? eye : eyeHide}
              style={{ width: 20, height: 20 }}
            />
          </button>
        </div>
      ) : (
        <Input
          style={{ ...props.inputStyle, marginTop: "1vh" }}
          required={props.required ? true : false}
          {...props}
        />
      )}
      {props.errorText && <ErrorText>{`${props.errorText}`}</ErrorText>}
    </div>
  );
};

const Input = tw.input`
w-full
p-2
pl-5
focus:outline-none 
focus:ring-1
focus:border-fbButtonColor
border
border-inputBorder
rounded-md`;

const InputEye = tw.input`
w-[90%]
p-1
pl-4
focus:outline-none 
`;

const TextArea = tw.textarea`
w-full
p-2
pl-5
focus:outline-none 
focus:ring-1
focus:border-fbButtonColor
border
border-inputBorder
rounded-md`;

const ErrorText = tw.p`
text-sm
text-red-500`;

export default CustomInput;
