import moment from 'moment';

const dayFromNow = (myDate) => {
  return moment(myDate).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    sameElse: 'DD MMMM YYYY'
  });
}

const getUserAge = (dob) => {
  let diff = moment().diff(moment(dob), 'years')
  return diff;
}

export { dayFromNow, getUserAge }