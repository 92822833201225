import { io as SocketIOClient } from "socket.io-client";
import Config from "@config";

export const socketFinishRoundKey = "match";
export const socketScoreKey = "score";
export const socketPermissionToExit = "match-permission";
export const common = "common";

var socket = null;

export const initializeSocket = () => {
  socket = SocketIOClient(Config.SOCKET, { transports: ["websocket"] });
  // socket = SocketIOClient("http://45.79.122.136:4000/socket", {
  //   transports: ["websocket"],
  // });

  socket.on("connect", (socket) => {
    console.log("socket is connect ", socket);
  });
  socket.on("disconnect", (socket) => {
    console.log("socket is disconnect ", socket);
  });

  return socket;
};

export const getSocket = () => {
  return socket;
};

export const registerSocketEvent = (eventName, callback) => {
  socket.on(eventName, callback);
  console.log("socket on ", eventName);
};

export const deregisterSocketEvent = (eventName) => {
  socket.off(eventName);
};

export const sendMessage = (eventName, message, isNet = true) => {
  try {
    isNet &&
      socket.emit(eventName, JSON.stringify(message), (res) => {
        console.log("socket emit", res);
      });
  } catch (error) {
    console.error("socket error:", error);
    // return error
  }
};
