import NextTournament from "@components/nextTournament";
import { connect } from "react-redux";
import MoreGames from "@components/moreGames";
import News from "@components/news";
import { WelcomeHeader, NormalHeader } from "@components";
import Advertisment from "@assets/images/Adword.png";
import { useTranslation } from "react-i18next";
import "./DashboardStyle.css";

import React, { useState } from "react";
import Popup from "../../components/popup/popup";

const Dashboard = ({ userName, topEight, winnerCount, prizeMoney }) => {
  const { t } = useTranslation("common");

  const data = [
    { title: t("dashboard.top8"), value: (topEight && "" + topEight) || "0" },
    {
      title: t("dashboard.wins"),
      value: (winnerCount && "" + winnerCount) || "0",
    },
    {
      title: t("dashboard.prizeMoney"),
      value: (prizeMoney && "" + prizeMoney) || "0",
    },
  ];

  const Wrapper = (props) => {
    return <div className="w-full lg:ml-10">{props.children}</div>;
  };

  const [isPopupVisible, setPopupVisible] = useState(false);

  const WrapperInner = (props) => {
    return (
      <div
        style={props.style}
        className={`${props.className} lg:block w-full lg:flex-col flex lg:max-h-[680px] custom-scroll p-2 mt-2 justify-center `}
      >
        {props.children}
      </div>
    );
  };

  return (
    <div>
      <div className="mt-[64px]">
        <WelcomeHeader data={data} userName={userName} />
      </div>
      <div className="mx-auto lg:px-[10%] px-[5%]">
        <div className="lg:flex justify-between">
          <Wrapper>
            <NormalHeader>{t("dasboardHeader.nextTour")}</NormalHeader>
            <WrapperInner style={{ alignItems: "center", overflow: "auto" }}>
              <div className="lg:flex-col md:flex-row sm:flex-col flex flex-col">
                <NextTournament />

                <div className="mt-[20px] lg:mt-[0px] center">
                  <img
                    src={Advertisment}
                    // useMap="#workmap"
                    className={
                      "cursor-pointer sm:w-[366px] w-[350px] lg:w-full md:mx-[24px] lg:mx-auto lg:my-[50px] md:w-[321px] object-fit rounded-[20px] mt-3 md:mt-0 "
                    }
                    onClick={() => setPopupVisible(true)} // Pop Up OnClick
                  />

                  {/* <map name="workmap">
                    <area
                      shape="rect"
                      coords="22, 164, 147, 212"
                      href="https://webapp.stg.s6m.io/"
                      target="_blank"
                      alt="appStore"
                    />
                    <area
                      shape="rect"
                      coords="170, 164, 305, 212"
                      href="https://webapp.stg.s6m.io/"
                      target="_blank"
                      alt="googlePlay"
                    />
                  </map> */}
                  {isPopupVisible ? (
                    <Popup onClose={() => setPopupVisible(false)} />
                  ) : null}
                </div>
              </div>
            </WrapperInner>
          </Wrapper>
          <Wrapper>
            <NormalHeader>{t("dasboardHeader.game")}</NormalHeader>
            <WrapperInner style={{ overflow: "auto" }}>
              <MoreGames />
            </WrapperInner>
          </Wrapper>
          <Wrapper>
            <NormalHeader>{t("dasboardHeader.news")}</NormalHeader>
            <WrapperInner
              style={{ overflow: "auto", justifyContent: "normal" }}
            >
              <News />
            </WrapperInner>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userHcp: state.user.hcp,
    userName: state.user.fname,
    topEight: state.user.topEight,
    winnerCount: state.user.winnerCount,
    prizeMoney: state.user.prizeMoney,
  };
};
export default connect(mapStateToProps, null)(Dashboard);
