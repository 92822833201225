import * as types from './types';

const initialState = {
  creditCardList: [],
  updated: null,
  creditCardUpdated: null,
  tournamentPayment: null,
  tournamentPaymentUpdate: null,
  transactionHistory: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_CREDIT_CARD: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        return {
          ...state,
          creditCardUpdated: new Date()
        }
      } else {
        return {
          ...state,
          creditCardUpdated: null
        }
      }
    }

    case types.GET_CREDIT_CARD_LIST: {
      if (action.payload &&
        action.payload.data &&
        action.payload.data.data &&
        action.payload.status === 200) {
        let creditCardList = action.payload.data.data;
        return {
          ...state,
          creditCardList: creditCardList,
        }
      } else {
        return {
          ...state,
          creditCardList: [],
        }
      }
    }

    case types.TRANSACTION_HISTORY: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let transactionHistory = action.payload.data;
        return {
          ...state,
          transactionHistory
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.DELETE_CREDIT_CARD: {
      if (action.payload &&
        action.payload.deletedId &&
        action.payload.status === 200) {
        let deletedId = action.payload.deletedId;
        let creditCardData = state.creditCardList;
        let filtered = creditCardData.filter(u => !deletedId.includes(u.id));
        return {
          ...state,
          creditCardList: filtered,
          updated: Date()
        }
      } else {
        return {
          ...state,
        }
      }
    }
    case types.TOURNAMENT_PAYMENT: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let tournamentPayment = action.payload.data;
        return {
          ...state,
          tournamentPayment: tournamentPayment,
          tournamentPaymentUpdate: Date()
        }
      } else {
        return {
          ...state,
          tournamentPayment: null
        }
      }
    }
    case types.LOGOUT: {
      return { ...initialState }
    }
    default: {
      return {
        ...state
      };
    }
  }

};

export default reducer;