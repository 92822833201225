import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { connect } from 'react-redux';
import * as action from '@store/user/actions';
import { isPassword } from '@utils';
import { CustomButton, CustomInput, MainHeaderText, Text } from '@components';
import { CustomModal } from './widgets'

const SignUpNext = ({ registerUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state.email;

  const [state, setState] = useState({
    name: '',
    lname: '',
    fname: '',
    password: '',
    tryAgain: false,
    buttonDisable: true,
    termsVisible: false,
  });

  const toggleModal = () => setState(prev => ({ ...prev, termsVisible: !state.termsVisible }))

  useEffect(() => {
    if (
      state.fname !== '' &&
      state.lname !== '' &&
      state.password.length >= 8
    ) {
      setState((prev) => ({
        ...prev,
        buttonDisable: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        buttonDisable: true,
      }));
    }
  }, [state.buttonDisable, state.password, state.fname, state.lname]);

  let isValidatePass =
    state.password === '' ? true : isPassword(state.password);

  const registration = () => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const regData = {
      email: email,
      password: state.password,
      fname: state.fname,
      lname: state.lname,
    };
    registerUser(regData).then((response) => {
      if (response && response.payload && response.payload.status === 200) {
        navigate('/')
        setState((prev) => ({ ...prev, isLoading: false }));
      } else {
        setState((prev) => ({ ...prev, isLoading: false, tryAgain: true }));
      }
    });
  };

  const Header = () => {
    return (
      <div className={`mb-10 self-start`}>
        <MainHeaderText>
          GET <MainHeaderText className={'text-maroon'}>STARTED</MainHeaderText>
        </MainHeaderText>
        <div className={`my-5`}>
          <Text>Get the best and unique golf experience </Text>
        </div>
      </div>
    );
  };

  return (
    <LoginPart>
      <div />
      <LoginContent>
        <Header />
        <InputContainer>
          <Text className={'mb-[30px]'}>{email}</Text>
          <div className="flex w-full">
            <Input className="mr-2">
              <CustomInput
                title={'Your First Name'}
                placeholder={'First Name'}
                type={'text'}
                className={'capitalize'}
                onChange={(event) =>
                  setState((prev) => ({ ...prev, fname: event.target.value }))
                }
              />
            </Input>
            <Input>
              <CustomInput
                title={'Your Last Name'}
                placeholder={'Last Name'}
                type={'text'}
                className={'capitalize'}
                onChange={(event) =>
                  setState((prev) => ({ ...prev, lname: event.target.value }))
                }
              />
            </Input>
          </div>
          <Input>
            <CustomInput
              title={'Password'}
              placeholder={'8+ character'}
              type={'password'}
              onChange={(event) =>
                setState((prev) => ({ ...prev, password: event.target.value }))
              }
              errorText={isValidatePass ? null : 'Enter a valid password.'}
              onKeyUp={e => (e.key === 'Enter') ? registration() : null}
            />
          </Input>
          <CustomButton
            title={'Done'}
            onClick={() => registration()}
            className={'mt-[10px] px-4'}
            disabled={state.buttonDisable}
            loading={state.isLoading}
          />

          <Text className={'mt-[65px]'}>
            Thanks for signing up! Based on your sign up, we would love to keep
            you posted with product news and tips for getting the most of S6M.
            You can opt out at any time. See our
            <span
              onClick={toggleModal}
              className="font-bold underline cursor-pointer"
            >
              {' '}
              Privacy Policy{' '}
            </span>
            for more information.
          </Text>
        </InputContainer>
      </LoginContent>
      <div className={'flex justify-start items-center w-[80%]'}>
        <div className={'flex items-center self-start whitespace-nowrap'}>
          <CustomButton
            title={'Sign In'}
            onClick={() => navigate('/personal-info')}
            reversStyle
          />
          <div className={'ml-4 lg:ml-8'}>
            <Text className={'overflow-hidden overflow-ellipsis'}>
              Already have an account?
            </Text>
          </div>
        </div>
      </div>
      <CustomModal isOpenModal={state.termsVisible} closeModal={toggleModal} />
    </LoginPart>
  );
};

const Input = tw.div`
flex 
flex-col
w-full
py-2`;

const LoginPart = tw.div`
flex
py-5
lg:w-full
w-4/5
lg:bg-white
lg:item-start
items-center
flex-col
justify-between`;

const LoginContent = tw.div`
lg:justify-start
lg:items-start
w-[80%]
flex 
flex-col
items-center
justify-center`;

const InputContainer = tw.div`
flex 
flex-col
w-full
lg:w-3/5
lg:justify-start
lg:items-start
justify-center 
items-center`;

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (data) => dispatch(action.registerUser(data)),
    updateUser: (data) => dispatch(action.updateUser(data)),
  };
};

export default connect(null, mapDispatchToProps)(SignUpNext);
