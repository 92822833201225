import React from 'react';
import tw from 'tailwind-styled-components';
import '../newsBox/newsBoxStyle.css';
import NewsBox from '../newsBox/index';


const News = () => Array(10).fill(10).map((_, i) => <NewsDiv key={i.toString()}><NewsBox /></NewsDiv>)

const NewsDiv = tw.div`
p-[10px] 
lg:p-0
sm:min-w-[50%]
min-w-full
lg:min-w-full

`

export default News
