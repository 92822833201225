import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";
import CustomModal from './signUpTermsModal';


const onPressGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  return await signInWithPopup(auth, provider)
    .then((result) => { return result?.user?.providerData?.[0] }).catch((error) => { return error })
}

const onPressFacebook = async () => {
  const provider = new FacebookAuthProvider();
  const auth = getAuth();
  return await signInWithPopup(auth, provider)
    .then((result) => { return result.user }).catch((error) => { return error })
}

const onPressApple = async () => {
  const provider = new OAuthProvider('apple.com');
  const auth = getAuth();
  return await signInWithPopup(auth, provider)
    .then((result) => { return result.user }).catch((error) => { return error })
}

export {
  onPressGoogle,
  onPressFacebook,
  onPressApple,
  CustomModal,
}