import * as types from './types';
const initialState = {
  concede: false,
  stroke: 0,
  strokeOne: 0,
  strokeTwo: 0,
  strokeThree: 0,
  strokeOneMulti: 0,
  strokeTwoMulti: 0,
  strokeThreeMulti: 0,
  onExpendOne: false,
  onExpendTwo: false,
  onExpendThree: false,
  onExpendOneMulti: false,
  onExpendTwoMulti: false,
  onExpendThreeMulti: false,
  concedeOne: false,
  concedeTwo: false,
  concedeThree: false,
  concedeOneMulti: false,
  concedeTwoMulti: false,
  concedeThreeMulti: false,
  isMulti: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONCEDE: {
      return {
        ...state,
        concede: !state.concede
      }
    }
    case types.ADD: {
      return {
        ...state,
        stroke: state.stroke + 1
      }
    }
    case types.MINUS: {
      return {
        ...state,
        stroke: state.stroke - 1
      }
    }
    case types.EXPEND: {
      const data = action.payload;
      if (data) {
        return {
          ...state,
          ...data,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.ON_CONCEDE: {
      const data = action.payload;
      if (data) {
        return {
          ...state,
          ...data,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.ADD_STROKE: {
      const data = action.payload;
      if (data.from === 'multi') {
        if (data.holeNumber === 1) {
          return {
            ...state,
            strokeOneMulti: state.strokeOneMulti + 1
          }
        }
        else if (data.holeNumber === 2) {
          return {
            ...state,
            strokeTwoMulti: state.strokeTwoMulti + 1
          }
        }
        else {
          return {
            ...state,
            strokeThreeMulti: state.strokeThreeMulti + 1
          }
        }
      }
      else {
        if (data.holeNumber === 1) {
          return {
            ...state,
            strokeOne: state.strokeOne + 1
          }
        }
        else if (data.holeNumber === 2) {
          return {
            ...state,
            strokeTwo: state.strokeTwo + 1
          }
        }
        else {
          return {
            ...state,
            strokeThree: state.strokeThree + 1
          }
        }
      }
    }
    case types.SUBTRACT_STROKE: {
      const data = action.payload;
      if (data.from === 'multi') {
        if (data.holeNumber === 1) {
          return {
            ...state,
            strokeOneMulti: state.strokeOneMulti - 1
          }
        }
        else if (data.holeNumber === 2) {
          return {
            ...state,
            strokeTwoMulti: state.strokeTwoMulti - 1
          }
        }
        else {
          return {
            ...state,
            strokeThreeMulti: state.strokeThreeMulti - 1
          }
        }
      }
      else {
        if (data.holeNumber === 1) {
          return {
            ...state,
            strokeOne: state.strokeOne - 1
          }
        }
        else if (data.holeNumber === 2) {
          return {
            ...state,
            strokeTwo: state.strokeTwo - 1
          }
        }
        else {
          return {
            ...state,
            strokeThree: state.strokeThree - 1
          }
        }
      }
    }
    case types.MULTI: {
      return {
        ...state,
        isMulti: !state.isMulti
      }
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default reducer;
