import React from 'react';
import tw from 'tailwind-styled-components/';

const NormalHeader = (props) => {
  return (
    <Header style={props.style}>
      {props.children}
      {props.rightComponent && props.rightComponent}
    </Header>
  )
}

const Header = tw.div`
flex
justify-between
font-bold
text-[12px]
leading-[32px]
tracking-[1px]
text-[rgb(166,173,187)]
py-[15px]
uppercase
border-0
border-genderBorder/20
border-b-[1px]
`;

export default NormalHeader;
