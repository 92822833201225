import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "../src/store/configureStore";
import reportWebVitals from "./reportWebVitals";
import { Loader } from "@components/";
import common_en from "./translations/en/language.json";
import common_fr from "./translations/fr/language.json";
import common_ge from "./translations/ge/language.json";
// import * as serviceWorker from './serviceWorker';

i18next.init({
  lng: "en",
  debug: false,
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
    ge: {
      common: common_ge,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <Suspense
          fallback={
            <div className="w-screen h-screen center">
              <Loader />
            </div>
          }
        >
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();
