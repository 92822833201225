import { useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import s6mLogo from '@assets/images/s6mLogo/s6mRounded.png';
import bg from '@assets/images/login-bg.jpeg'
import compressedLogo from '@assets/images/s6mLogo/compressedlogo.png'
import { LoginWidget, SignUp, SignUpNext, SocialLoginDetails, Verification, ResetPassword } from './widgets';

const Login = () => {
  const { pathname } = useLocation();
  const Component = () => {
    switch (pathname) {
      case '/sign-up':
        return <SignUp />;
      case '/sign-up-next':
        return <SignUpNext />;
      case '/verification':
        return <Verification />;
      case '/reset-password':
        return <ResetPassword />;
      case '/social-login-details':
        return <SocialLoginDetails />;
      default:
        return <LoginWidget />;
    }
  };
  return (
    <Container>
      <HeaderImg src={compressedLogo} />
      <div className="w-[80%] relative hidden lg:block">
        <TextBackground
          style={{
            fontFamily: 'Oswald, sans-serif',
            // background: 'url(https://images2.minutemediacdn.com/image/fetch/w_2000,h_2000,c_fit/https%3A%2F%2Ffansided.com%2Fwp-content%2Fuploads%2Fgetty-images%2F2017%2F07%2F1024697286.jpeg)',
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            color: 'white',
            WebkitBackgroundClip: 'text',
          }}
        >
          New
          <br />
          Format
        </TextBackground>
        <FooterText>
          Revolutionize your way to enjoying golf time by join Super6Matchplay
        </FooterText>
        <Img 
          src={bg}
        />
      </div>
      <Component />
    </Container>
  );
};

const Container = tw.div`
justify-center
bg-white
h-screen 
w-screen 
flex`;

const TextBackground = tw.p`
font-extrabold
text-[100px]
absolute
top-[50%]
p-[20%]
translate-y-[-50%]
z-30
`;

const Img = tw.img`
brightness-75
lg:block
hidden
h-screen 
object-right 
object-cover
`;

const HeaderImg = tw.img`
w-[80px]
h-[80px]
lg:w-[100px]
lg:h-[100px]
absolute 
-top-1
left-10
filter
drop-shadow-md
lg:drop-shadow-none 
z-10`;

const FooterText = tw.p`
absolute
bottom-[30px]
px-[15%]
leading-[27px]
tracking-[0.16px]
z-30

text-[15px]
text-white`
export default Login;
