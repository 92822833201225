import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import tw from 'tailwind-styled-components';
import { CustomButton, CustomInput, MainHeaderText, Text } from '@components';
import { isPassword } from '@utils';
import * as actions from '@store/user/actions';
import { useTranslation } from 'react-i18next';

const ResetPassword = ({ resetPassword }) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();
  const { state: { email, otp, from } } = location;
  const [state, setState] = useState({
    pass: '',
    confirmPass: '',
    isLoading: false,
    buttonDisable: true,
  })

  useEffect(() => {
    if (isPassword(state.pass) === true && state.pass !== '' && state.pass === state.confirmPass) {
      setState((prev) => ({
        ...prev,
        buttonDisable: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        buttonDisable: true,
      }));
    }
  }, [state.buttonDisable, state.confirmPass, state.pass]);

  let isValidatePass = state.pass === '' ? true : isPassword(state.pass)
  let isValidateConfirmPass = state.confirmPass === '' ? true : (isPassword(state.pass) && state.pass === state.confirmPass)

  const onContinue = () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    let data = {
      email: email,
      otp: +otp,
      password: state.confirmPass,
    };

    resetPassword(data).then((res) => {
      if (res?.payload?.data && res?.payload?.status === 200) {
        setState((prev) => ({ ...prev, isLoading: false }));
        if (window.confirm('Password succesfully updated') === true) {
          navigate('/');
        } else navigate('/');
      } else {
        setState((prev) => ({ ...prev, isLoading: false }));
      }
    });
  };

  const Header = () => {
    return (
      <div className={`mb-10 self-start`}>
        <MainHeaderText>
          New <MainHeaderText className={'text-maroon'}>Password</MainHeaderText>
        </MainHeaderText>
        <div className={`my-5`}>
          <Text>Required minimum 8 letters.</Text>
        </div>
      </div >
    );
  }

  return (
    <LoginPart>
      <div />
      <LoginContent>
        <Header />
        <InputContainer>
          <Input>
            <CustomInput
              title={'Password'}
              placeholder={'Your password'}
              type={'password'}
              onChange={(event) => setState(prev => ({ ...prev, pass: event.target.value }))}
              errorText={(isValidatePass) ? '' : 'Minimum length is 8.'}
            />
            <div className='mt-[16px]'>
              <CustomInput
                title={'Confirm password'}
                placeholder={'Confirm password'}
                type={'password'}
                onChange={(event) => setState(prev => ({ ...prev, confirmPass: event.target.value }))}
                errorText={(isValidateConfirmPass) ? '' : 'Please enter the same as above'}
                onKeyUp={e => (e.key === 'Enter') ? onContinue() : null}
              />
            </div>
          </Input>
          <CustomButton title={'Continue'} onClick={() => onContinue()} className={'mt-[50px] px-4'} disabled={state.buttonDisable} loading={state.isLoading} />
        </InputContainer>
      </LoginContent>
      <div className={'flex justify-start items-center w-[80%]'}>
        <div className={'flex items-center self-start whitespace-nowrap'}>
          <CustomButton title={'Sign In'} onClick={() => navigate('/')} reversStyle />
          <div className={'ml-4 lg:ml-8'}>
            <Text className={'overflow-hidden overflow-ellipsis'}>
              Already have an account?
            </Text>
          </div>
        </div>
      </div>
    </LoginPart>
  );
}

const Input = tw.div`
flex 
flex-col
w-full
py-2`

const LoginPart = tw.div`
flex
py-5
lg:w-full
w-4/5
lg:bg-white
lg:item-start
items-center
flex-col
justify-between`;

const LoginContent = tw.div`
lg:justify-start
lg:items-start
w-[80%]
flex 
flex-col
items-center
justify-center`;

const InputContainer = tw.div`
flex 
flex-col
w-full
lg:w-3/5
lg:justify-start
lg:items-start
justify-center 
items-center`;


const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data) => dispatch(actions.resetPass(data)),
  };
};
export default connect(null, mapDispatchToProps)(ResetPassword);