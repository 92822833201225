import React from 'react';
import tw from 'tailwind-styled-components';
import { XIcon } from '@heroicons/react/solid';
import { OutsideAlerter } from '@utils';

const Modal = (props) => {
  const hidden = !props.isOpenModal ? 'hidden' : 'flex'
  return (
    <div className={`${hidden} fixed top-0 left-0 right-0 bottom-0 bg-black/20 w-screen h-screen flex justify-center items-center z-[1000]`}>
      <OutsideAlerter close={props.closeModal} className={Container}>
        {props.children}
      </OutsideAlerter>
    </div >
  );
}

const Container = `
md:w-[504px]
md:h-[504px]
w-[90%]
h-[435px]
max-h-[90%]
bg-white 
shadow-3xl
rounded-[8px]
overflow-hidden
p-[16px]
`

const ImgBox = tw.div`
relative
w-full
h-[65%]
rounded-[8px]
border-[1px]
border-[rgb(222, 227, 236 )]
`

const Img = tw.img`
w-full
h-full
object-contain
`

const Heading = tw.div`
whitespace-nowrap
overflow-hidden
overflow-ellipsis
font-semibold
text-[15px]
mt-[21px]
mb-[14px]
`
const Discription = tw.div`
text-gray51
text-[14px]
tracking-[0.17px]
leading-[17px]
`

export default Modal;