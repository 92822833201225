import { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';

export default function Example(props) {
  const [text, setText] = useState('');
  useEffect(() => {
    if (props.selected) {
      let data = props?.data?.filter(item => props.selected === item?.id)
      let selectedData = data[0]
      if (selectedData?.name) {
        setText(selectedData?.name)
      }
    } else {
      setText('')
    }
  }, [props.selected])

  const onChange = (e) => {
    props.onChangeText(e)
    setText(e.target.value)
  }

  const afterLeave = (e) => {
    if (!props.selected) {
      setText('')
    } else {
      let data = props?.data?.filter(item => props.selected === item?.id)
      let selectedData = data[0]
      if (selectedData?.name) {
        setText(selectedData?.name)
      } else {
        setText('')
      }
    }
  }

  return (
    <Combobox
      disabled={props.disabled}
      value={text}
      onChange={props.onSelect}
    >
      <div className="relative mb-[24px]">
        <Combobox.Button className="relative w-full text-left bg-white rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
          <Combobox.Input
            onBlur={afterLeave}
            onFocus={props.onFocus}
            className={props.inputClassName}
            placeholder={props.default}
            autoComplete={'off'}
            onChange={onChange}
          />
        </Combobox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {props.loader ? <Combobox.Options className="center z-[100] shadow-3xl scroll-y-none absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <div className='w-10 h-10 rounded-full border-4 border-fbButtonColorDisable/20 border-t-fbButtonColor animate-spin' />
          </Combobox.Options>
            : props.data?.length === 0 ?
              <Combobox.Options className="center z-[100] shadow-3xl scroll-y-none absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                Nothing found.
              </Combobox.Options>
              : <Combobox.Options className="z-[100] shadow-3xl scroll-y-none absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {
                  props?.data?.map((data) => (
                    <Combobox.Option
                      key={data.id}
                      className={({ active }) =>
                        `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? 'text-white bg-fbButtonColor' : 'text-gray-900 bg-white'
                        }`
                      }
                      value={data}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={`block truncate ${(props.selected === data.id) ? 'font-bold' : 'font-normal'
                              }`}
                          >
                            {data.name}
                          </span>
                          {(props.selected === data.id) ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-fbButtonColor'
                                }`}
                            >
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                }
              </Combobox.Options>}
        </Transition>
      </div>
    </Combobox>
  )
}
