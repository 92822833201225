import "intl";
import "intl/locale-data/jsonp/en";

const getCurrencySymbol = (currencyCode, amount = 0) => {
  let getCurrency = currencyCode || 'USD';
  let updatedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: getCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(amount);
  return updatedAmount;
}

export { getCurrencySymbol };