import React from 'react';
import tw from 'tailwind-styled-components';

const ToolTip = ({ description, tooltipDown }) => {
  return (
    <div className='group relative'>
      <Circle>
        <I>i</I>
      </Circle>
      <Card style={{ boxShadow: '5px 7px 20px rgb(56 98 175 / 37%)' }} down={tooltipDown}>
        <LeftArrow down={tooltipDown} />
        {description}
      </Card>
    </div>
  )
}

const Circle = tw.div`
group-hover:border-fbButtonColor
group-hover:text-fbButtonColor
w-[15px]
h-[15px]
rounded-full
center
border-[1.5px]
border-black
`
const I = tw.p`
text-[10px]
font-bold
select-none
`
const LeftArrow = tw.div`
${p => p.down ? 'top-[-5px]' : 'bottom-[-6px]'}
w-[10px]
h-[10px]
bg-fbButtonColor
absolute
rotate-[-45deg]
right-[21px]
border-fbButtonColor
border-l-[1.5px]
border-b-[1.5px]
`
const Card = tw.div`
${p => p.down ? 'bottom-[-32px]' : 'bottom-[50px]'}
hidden
group-hover:flex 
w-[200px]
h-auto
rounded-[8px]
bg-fbButtonColor
border-[1.5px]
border-fbButtonColor
z-[500]
right-[-20px]
absolute
px-[15px]
py-[7px]
text-[14px]
text-white
items-center
`

export default ToolTip