import { getMessaging, getToken } from "firebase/messaging";

export const notification = () => {
  console.log('adf', window.Notification && window.Notification.permission)
  if (window.Notification && Notification.permission === 'granted') {
    var img = 'static/media/s6MLogo3x.643d53471d6cdf4d81fe.png';
    var text = 'Welcome to Super 6 Matchplay';
    new Notification('Super 6 Matchplay', { body: text, icon: img });
  } else if (window.Notification && Notification.permission === 'default') {
    Notification.requestPermission().then();
  }
}

export const getNotifToken = (setTokenFound) => {
  const messaging = getMessaging();
  getToken(messaging, { vapidKey: "BPrbW6hF6z7K_hXhaIi8jS2r10aahBD8P0jfj0T_fr3hZMp8D9uXQfPxoKikZwEyK815Iy7vKraErNbtYzG8URQ" }).then((currentToken) => {
    if (currentToken) {
      console.log('token-currentToken', currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}