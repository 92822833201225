import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import tw from 'tailwind-styled-components';
import moment from 'moment';
import { combine } from '@utils'
import { connect } from 'react-redux';
import { WelcomeHeader, ProgressBar, Text } from '@components';
import { FilterIcon, PlusSmIcon } from '@heroicons/react/solid';
import { NormalHeader, Loader } from '@components';
import AlarmIcon from '@assets/images/alarmIcon.png';
import Location from '@assets/images/locationIcon.png';
import { OutsideAlerter } from '@utils';
import { animated,useSpring } from '@react-spring/web';
import * as actions from '@store/tournament/actions';
import CircularProgressBar from './circularProgressBar';
import { useTranslation } from 'react-i18next';
import './index.css';

const ClubDashboard = (props) => {
  const { t } = useTranslation('common');
  let location = useLocation()
  const [state, setState] = useState({
    loaderForDraft: true,
    isdeleteModalOpen:false,
    deleteTourId:null,
    isdeleted:false,
    loaderForPublish: true,
  });

  useEffect(() => {
    if (props?.userId) {
      const filterObject = {
        where: {
          userId: { '$oid': `${props.userId}` },
          state: 'draft',
          status: 'inactive',
        },
      };

      props.getMyDraftTournamentList(filterObject).then((res) => {
        if (res?.payload?.data && res?.payload?.status === 200) {
          setState((prev) => ({ ...prev, loaderForDraft: false }));
        } else {
          setState((prev) => ({ ...prev, loaderForDraft: false }));
        }
      });
    }
  }, [props?.userId, location?.state?.reload, props.tournamentList]);

  useEffect(() => {
    if (props?.userId) {
      const filerObject = {
        where: {
          userId: `${props.userId}`,
          state: 'draft',
          status: 'active',
        },
        include: [
          {
            relation: "tournamentType",
          },
          {
            relation: "players",
            scope: { fields: ['tournamentId'] }
          }
        ]
      };
      const filter = JSON.stringify(filerObject);
      props.getMyPublishTournamentList(filter).then((res) => {
        if (res?.payload?.data && res?.payload?.status === 200) {
          setState((prev) => ({ ...prev, loaderForPublish: false }));
        } else {
          setState((prev) => ({ ...prev, loaderForPublish: false }));
        }
      });
    }
  }, [props?.userId]);

  const navigate = useNavigate();

  let data = [
    { title: t('dashboard.players'), value: '188' },
    { title: t('dashboard.games'), value: '3' },
    { title: t('dashboard.payouts'), value: '12.750 $' },
  ];

  const LoaderComponent = () => {
    return (
      <div className="w-full h-[275px] center ">
        <div className="center">
          <Loader />
        </div>
      </div>
    );
  };

  const Empty = () => {
    return (
      <div className="w-full h-[275px] center ">
        <div className="center">
          <Loader text={t('dashboard.noPublishTournamentFound')} />
        </div>
      </div>
    );
  };

  const Box = (props) => {
    let condStyle =
      props.index === 0
        ? 'ml-[24px] border-[rgba(166,166,167,0.35)] bg-white items-center justify-center'
        : 'ml-[24px]  border-transparent bg-[rgb(246,246,246)] justify-between';
    return (
      <Link
        to={props.to}
        state={props.state}
        className={`${BoxStyles} ${condStyle}`}
        onClick={props.onClick}
      >
        {props.children}
      </Link>
    );
  };

  const deleteTour = (prop) => {
    props.deleteTournament(prop).then((res) => {});
  };

  const DeleteTourModal = (props) => {
    const modalBackground = useSpring({
      backgroundColor: state.isdeleteModalOpen
        ? "rgba(0,0,0,0.5)"
        : "transparent",
    });
    return (
      <animated.div
        style={modalBackground}
        className={`fixed top-0 left-0 right-0 bottom-0  w-screen h-screen flex justify-center items-center custom-scroll overflow-auto z-[1000]`}
      >
        <animated.div
          className={`rounded-xl absolute right-0 lg:left-[40%] md:left-[30%] sm:left-[20%] left-[2%] top-[40%] bottom-0 flex md:w-[400px] sm:w-[300px] w-[70%] min-w-[260px] m-2 h-fit border-transparent bg-[rgb(246,246,246)] p-6 z-90`}
        >
          <OutsideAlerter
            className={""}
            close={() =>
              setState((prev) => ({ ...prev, isdeleteModalOpen: false }))
            }
          >
            {`Are you sure you want to Delete the Tournament?`}
            <div className="flex flex-row item-center justify-evenly w-[100%]">
              <Options
                onClick={() => {
                  deleteTour(state.deleteTourId);
                  setState((prev) => ({ ...prev, isdeleteModalOpen: false }));
                }}
                className=""
              >{`Yes`}</Options>
              <Options onClick={props.close}>{`No`}</Options>
            </div>
          </OutsideAlerter>
        </animated.div>
      </animated.div>
    );
  };

 const MyTournamentBox = ({ item, index }) => {
    let pagePosition = item?.pagePosition || 0
    let intWidth = (pagePosition / 7) * 100
    return (
      <Box to={`/my-tournament/${item?.id}/${pagePosition || 1}`} state={item} index={index}>
        <div>
          <div className='flex w-[100%] justify-between'>
          <TournamentName>{item?.name || 'Tournament Name'}</TournamentName>
          <div onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setState((prev)=>({...prev,isdeleteModalOpen:true,deleteTourId:item.id}));}}>
            <img height={20} width={20} src={require('@assets/images/garbage.png')} alt='Delete'/></div>
          </div>          
          <div className="flex items-center">
            <div className='mr-[5px]'>
              <CircularProgressBar sqSize={15} percentage={intWidth || 50} strokeWidth={2.5} />
            </div>
            <SubText>{t('dashboard.inProgress')}</SubText>
          </div>
          <SubText>
            {t('dashboard.lastUpdate')}: {moment(item.modified).format('DD MMM YYYY, h:mma')}
          </SubText>
        </div>
        <ProgressBar intWidth={intWidth || 50} height={8} text={t('dashboard.progress')} />
      </Box>
    );
  };

  const InfoTape = ({ icon, value, className }) => {
    return (
      <div className={`flex items-center ${className}`}>
        <img src={icon} className="w-5 h-5" />
        <TextContent style={{ color: 'rgb(51, 51, 51)', marginLeft: '13px', width:'60%'}}>
          {value}
        </TextContent>
      </div>
    );
  };

  const Tournament = ({ item, index }) => {
    let enrollPlayers = item?.players?.length || 0
    let intWidth = (enrollPlayers / item?.field) * 100

    return (
      <div className={`ml-[12px] snap-scroll pl-[22px]`}>
        <HeaderDate>{moment(item?.startDate).format('D MMMM')}</HeaderDate>
        <TournamentBox>
          <div>
            <TournamentName>{item?.name || 'Tournament name'}</TournamentName>
            <SubText>{item?.tournamentType?.name || 'Tournament Type'}</SubText>
          </div>
          <div>
            <InfoTape
              icon={AlarmIcon}
              value={item?.startTime ? moment.utc(item?.startTime, 'HH:mm').local().format('h:mm A') : '00:00'}
              className={'mb-[16px]'}
            />
            <InfoTape icon={Location} value={item?.clubAddress || 'club address'} />
          </div>
          <ProgressBar
            intWidth={intWidth || 0}
            height={3}
            text={t('dashboard.registeredPlayers')}
            barColor={'rgba(151, 151, 151, 0.13)'}
            thumbColor={'rgb(56, 98, 175)'}
          />
        </TournamentBox>
      </div>
    );
  };

  const DraftTournamentList = () => {
    const data = props?.myDraftTournamentList;
    let myDraftTournamentList = data?.slice().sort((a, b) => combine(a?.startDate, a?.startTime) - combine(b?.startDate, b?.startTime))
    return (
      <div className="overflow-hidden">
        {state.loaderForDraft ? (
          <LoaderComponent />
        ) : (
          <Container>
            <Box to={`/my-tournament/0/1`} index={0}>
              <Circle>
                <PlusSmIcon className="w-[25px] h-auto" />
              </Circle>
              <LoadText style={{ fontFamily: 'Source Sans, sans-serif' }}>
                {t('dashboard.createNewTournament')}
              </LoadText>
            </Box>
            {myDraftTournamentList.reverse().map((item, index) => (
              <MyTournamentBox item={item} index={index + 1} />
            ))}
          </Container>
        )}
      </div>
    );
  };

  const PublishTournamentList = () => {
    const data = props?.myPublishTournamentList;
    let myPublishTournamentList = data?.slice().sort((a, b) => combine(a?.startDate, a?.startTime) - combine(b?.startDate, b?.startTime))
    return (
      <div className="mb-[58px]">
        <div className="lg:mx-[10%] mx-[5%] mt-[60px]">
          <NormalHeader>{t('dashboard.tournaments')}</NormalHeader>
        </div>
        {state.loaderForPublish ? (
          <LoaderComponent />
        ) : (
          <div className="flex mt-[20px] overflow-auto pb-[20px] custom-scroll snap-scroll lg:mx-[10%] mx-[5%] pr-[3px]">
            {_.isEmpty(myPublishTournamentList) ? (
              <Empty />
            ) : (
              myPublishTournamentList
                .reverse()
                .map((item, index) => <Tournament item={item} index={index} />)
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <MainContainer>
      {state.isdeleteModalOpen && (
        <DeleteTourModal
          close={() =>
            setState((prev) => ({ ...prev, isdeleteModalOpen: false }))
          }
        />
      )}
      <WelcomeHeader club data={data} />
      <DraftTournamentList />
      <PublishTournamentList />
    </MainContainer>
  );
};

const MainContainer = tw.div`
mt-[60px]
`;
const Container = tw.div`
flex 
w-full 
mt-[100px] 
lg:ml-[10%] ml-[5%]
overflow-auto 
scroll-x-none
snap-scroll
`;
const TournamentName = tw.p`
font-bold
text-[19px]
tracking-[0.23px]
`;
const SubText = tw.p`
text-[14px]
tracking-[0.15px]
leading-[23px]
opacity-60
`;
const Options=tw.button`
bg-fbButtonColor 
text-white 
font-bold 
py-2 
px-10 
rounded-3xl 
mt-3 
`;

const BoxStyles = `
cursor-pointer
border-[1px]
min-w-[320px] 
w-[320px] 
h-[200px]
rounded-[18px] 
p-[24px] 
flex 
flex-col 
select-none
snap-scroll
`;
const Circle = tw.div`
w-[52px]
h-[52px]
rounded-full
bg-[rgba(166,166,167,0.12)]
flex
justify-center
items-center
`;
const LoadText = tw.div`
mt-[36px]
text-[16px]
tracking-[0.19px]
leading-[24px]
`;
const TournamentBox = tw.div`
flex
flex-col
select-none
justify-between
min-w-[320px]
h-[222px]
shadow-3xl
rounded-[18px]
px-[24px]
py-[20px]
`;

const HeaderDate = tw.p`
font-bold
text-[15px] 
tracking-[0.18px] 
ml-[18px] 
mb-[16px] 
text-fbButtonColor`;

const TextContent = tw.text`
TextTourCard1
whitespace-pre-line
items-start
`;


const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    myDraftTournamentList: state.tournament.myDraftTournamentList,
    tournamentList:state.tournament.tournamentList,
    myPublishTournamentList: state.tournament.myPublishTournamentList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTournamentDetails: (data) => dispatch(actions.getTournamentDetails(data)),
    getMyDraftTournamentList: (data) => dispatch(actions.getMyDraftTournamentList(data)),
    getMyPublishTournamentList: (data) => dispatch(actions.getMyPublishTournamentList(data)),
    deleteTournament: (data) => dispatch(actions.deleteTournament(data)),
    addMyTournament: (data) => dispatch(actions.addMyTournament(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClubDashboard);
