import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { firebase } from ".";

const firebaseConfig = {
  apiKey: "AIzaSyBL0ePaJvqG538CqVtzH9vW_sSLbOdIfBo",
  authDomain: "super6matchplay.firebaseapp.com",
  projectId: "super6matchplay",
  storageBucket: "super6matchplay.appspot.com",
  messagingSenderId: "616101585554",
  appId: "1:616101585554:web:0650c08cd17e4ecf183c4b",
  measurementId: "G-J2HEJSWQGD"
};

const app = firebase.app.length && initializeApp(firebaseConfig)
// const app = initializeApp(firebaseConfig)
getAnalytics(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export default app;

