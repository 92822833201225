import * as types from './types';

const initialState = {
prizes:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PRIZES: {
      if (action.payload) {
        let data = action.payload;
        return {
          ...state,
          prizes: data,
        }
      } else {
        return {
          ...state,
        }
      }
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default reducer;