import { combineReducers } from 'redux';
import user from './user/reducer';
import gameStart from './gameStart/reducer';
import tournament from './tournament/reducer';
import payment from './payment/reducer';
import proPrize from './ProPrize/reducer';

const appReducer = combineReducers({
  user,
  gameStart,
  tournament,
  payment,
  proPrize
});

const reducer = (state, action) => {
  return appReducer(state, action);
};


export default reducer;