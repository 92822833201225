// Popup.js

import React, { useState, useEffect } from 'react';

function Popup({ onClose }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Delay the appearance of the pop-up by 10 seconds
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
    localStorage.setItem('lastClosedTime', new Date().getTime().toString());
  };

  const getPopupSize = () => {
    // Determine the pop-up size based on the device width
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 'large';
    } else if (screenWidth >= 768) {
      return 'medium';
    } else {
      return 'small';
    }
  };

  const getButtonSize = () => {
    // Determine the button size based on the device width
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 'large';
    } else if (screenWidth >= 768) {
      return 'medium';
    } else {
      return 'small';
    }
  };

  const popupSize = getPopupSize();
  const buttonSize = getButtonSize();

  return (
    <>
      {isVisible && (
        <div className={`fixed inset-0 flex items-center justify-center z-50`}>
          <div className={`bg-blue-900 p-8 rounded-3xl shadow-lg text-white`}>
            <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold mb-4">Want to try our mobile app?</p>
            <div className="mt-4 flex justify-center">
              <a
                href="https://www.apple.com"
                target="_blank"
                rel="noopener noreferrer"
                className={`bg-white text-black px-4 py-2 rounded font-bold text-lg ${buttonSize}`}
                onClick={handleClose}
              >
                {popupSize === 'large' ? 'IOS App' : 'IOS App'}
              </a>
              <div className="mx-2"></div> {/* Add this div for spacing */}
              <a
                href="https://play.google.com/store/apps/details?id=com.s6m&pcampaignid=web_share"
                target="_blank"
                rel="noopener noreferrer"
                className={`bg-white text-black px-4 py-2 rounded font-bold text-lg ${buttonSize}`}
                onClick={handleClose}
              >
                {popupSize === 'large' ? 'Android App' : 'Android App'}
              </a>
            </div>
            <div className="text-center mt-4">
              <button className={`bg-white text-black px-4 py-2 rounded font-bold text-lg ${buttonSize}`} onClick={handleClose}>
                {popupSize === 'large' ? 'Close' : 'Close'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;
