import * as types from './types';

const initialState = {
  loading: true,
  myDraftTournamentList: [],
  myPublishTournamentList: [],
  playedTournamentList: [],
  tournamentplayList: [],
  tournamentDetails: {},
  tournamentsPlayer: [],
  localTournaments: [],
  nextTournament: null,
  tournamentSearch: null,
  tournamentPrizes: [],
  tournamentTypes: [],
  tournamentScore: [],
  scoreCard: [],
  tournamentList: [],
  playedPlayer: [],
  myTournament: [],
  playedScore: [],
  userReward: [],
  facilities: [],
  userPrize: [],
  ranking: [],
  clubs: [],
  track: [],
  course: [],
  updateRounds: Date(),
  updatePlayData: Date(),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOURNAMENTS: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        return {
          ...state,
          tournamentList: action?.payload?.data.reverse(),
          loading: false
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.PLAYED_TOURNAMENTS: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let playedTournamentList = action.payload.data;
        return {
          ...state,
          playedTournamentList,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.DRAFT_TOURNAMENT_DETAILS: {
      return {
        ...state,
      }
    }
    case types.MY_DRAFT_TOURNAMENT: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let data = action.payload.data?.documents || []
        let myDraftTournamentList = []
        data?.map((item, index) => {
          myDraftTournamentList.push({ ...item, id: item?._id ? item?._id : item?.id })
        })
        return {
          ...state,
          myDraftTournamentList,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.ALL_PRIZE_UPLOAD: {
      return {
        ...state,
      }
    }
    case types.UPDATE_TOURNAMENT: {
      return {
        ...state,
      }
    }
    case types.MY_PUBLISH_TOURNAMENT: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let myPublishTournamentList = action.payload.data;
        return {
          ...state,
          myPublishTournamentList,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.ADD_MY_TOURNAMENTS: {
      if (action.payload &&
        action.payload.data) {
        let myTournament = action.payload.data;
        return {
          ...state,
          myTournament,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.TOURNAMENTS_TYPES: {
      if (action.payload &&
        action.payload.data) {
        let tournamentTypes = action.payload.data;
        let data = tournamentTypes && JSON.stringify(tournamentTypes)
        localStorage.setItem('tournamentTypes', data)
        return {
          ...state,
          tournamentTypes,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.TOURNAMENT_SEARCH: {
      if (action.payload &&
        action.payload.data) {
        let tournamentSearch = action.payload.data;
        return {
          ...state,
          tournamentSearch,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.BLANK_TOURNAMENT_DETAILS: {
      return {
        ...state,
        tournamentDetails: null
      }
    }
    case types.UPDATE_ROUNDS: {
      return {
        ...state,
        updateRounds: Date()
      }
    }
    case types.UPDATE_PLAY_DATA: {
      return {
        ...state,
        updatePlayData: Date()
      }
    }
    case types.TOURNAMENT_PLAY_LIST: {

      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let tournamentData = action.payload.data;
        return {
          ...state,
          nextTournament: tournamentData.nextTournament,
          tournamentplayList: tournamentData.tournaments,
          loading: false
        }
      } else {
        return {
          ...state,
          nextTournament: null,
          tournamentplayList: []
        }
      }
    }
    case types.USER_REWARD: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let userReward = action.payload.data;
        return {
          ...state,
          userReward,
        }
      }
      else {
        return {
          ...state,
          userReward: null
        }
      }
    }
    case types.USER_PRIZE: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let userPrize = action.payload.data;
        return {
          ...state,
          userPrize,
        }
      }
      else {
        return {
          ...state,
          userPrize: null
        }
      }
    }
    case types.PLAYER_TOURNAMENT: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let tournamentsPlayer = action.payload.data;
        return {
          ...state,
          tournamentsPlayer,
        }
      }
      else {
        return {
          ...state,
          tournamentsPlayer: null
        }
      }
    }
    case types.PLAYED_PLAYER: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let playedPlayer = action.payload.data;
        return {
          ...state,
          playedPlayer,
        }
      }
      else {
        return {
          ...state,
          playedPlayer: null
        }
      }
    }
    case types.MATCHESSCORES: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let tournamentScore = action.payload.data;
        return {
          ...state,
          tournamentScore,
        }
      }
      else {
        return {
          ...state,
          tournamentScore: []
        }
      }
    }
    case types.SCORECARD: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let scoreCard = action.payload.data;
        return {
          ...state,
          scoreCard,
        }
      }
      else {
        return {
          ...state,
          scoreCard: []
        }
      }
    }
    case types.PLAYED_SCORES: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let playedScore = action.payload.data;
        return {
          ...state,
          playedScore,
        }
      }
      else {
        return {
          ...state,
          playedScore: []
        }
      }
    }
    case types.REGISTRATIONS_VALIDATION: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        return {
          ...state,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.REGISTRATION_STATE: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        return {
          ...state,
        }
      }
      else {
        return {
          ...state,
        }
      }
    }
    case types.LOCAL_TOURNAMENTS: {
      if (action.payload) {
        let data = action.payload;
        return {
          ...state,
          localTournaments: data,
        }
      }
      else {
        return {
          ...state,
          localTournaments: []
        }
      }
    }
    case types.ADD_LOCAL_TOURNAMENTS: {
      if (action.payload) {
        let data = action.payload;
        let tournamentArray = state.localTournaments
        let allTournament = state.tournamentList
        tournamentArray.splice(0, 0, data)
        allTournament.splice(0, 0, data)
        return {
          ...state,
          localTournaments: tournamentArray,
          tournamentList: allTournament
        }
      }
      else {
        return {
          ...state,
          localTournaments: []
        }
      }
    }
    case types.DELETE_TOURNAMENT: {
      if (action.payload) {
        let deletedId = action.payload;
        let tournamentArray = state.tournamentList.filter(item => item.id !== deletedId)
        return {
          ...state,
          tournamentList: tournamentArray,
        }
      }
      else {
        return {
          ...state,
          tournamentList: []
        }
      }
    }
    case types.TOURNAMENT_DETAILS: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let tournamentDetails = action.payload.data;
        return {
          ...state,
          tournamentDetails,
        }
      }
      else {
        return {
          ...state,
          tournamentDetails: null
        }
      }
    }
    case types.TOURNAMENT_PRIZES: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let tournamentPrizes = action.payload.data;
        return {
          ...state,
          tournamentPrizes,
        }
      } else {
        return {
          ...state,
          tournamentPrizes: null
        }
      }
    }
    case types.CLUB: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let clubList = action.payload.data
        let data = clubList && JSON.stringify(clubList)
        localStorage.setItem('clubList', data)
        return {
          ...state,
          clubs: clubList,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.TRACK: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let trackList = action.payload.data || [];
        // let data = trackList && JSON.stringify(trackList)
        // localStorage.setItem('trackList', data)
        return {
          ...state,
          track: trackList
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.COURSE: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let courseList = action.payload.data;
        // let data = courseList && JSON.stringify(courseList)
        // localStorage.setItem('courseList', data)
        return {
          ...state,
          course: courseList
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.FACILITIES: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let facilitiesList = action.payload.data || [];
        // let data = facilitiesList && JSON.stringify(facilitiesList)
        // localStorage.setItem('facilitiesList', data)
        return {
          ...state,
          facilities: facilitiesList,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.LEAGUE_RANKS: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let ranking = action.payload.data;
        let data = ranking && JSON.stringify(ranking)
        localStorage.setItem('ranking', data)
        return {
          ...state,
          ranking: ranking,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.RESUME_TOURNAMENT_TYPES: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        return {
          ...state,
          tournamentTypes: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.RESUME_LEAGUE_RANKS: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        return {
          ...state,
          ranking: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.RESUME_FACILITIES: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        return {
          ...state,
          facilities: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.RESUME_TRACK: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let track = action.payload.data;
        return {
          ...state,
          track
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.RESUME_COURSE: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let course = action.payload.data;
        return {
          ...state,
          course
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.RESUME_CLUB: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let clubs = action.payload.data;
        return {
          ...state,
          clubs
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.RESUME_LEAGUE_RANKS: {
      if (action.payload) {
        let ranking = [];
        return {
          ...state,
          ranking: ranking,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.WAITING: {
      if (action.payload) {
      } else {
        return {
          ...state
        }
      }
    }
    case types.REMOVE_MATCH_DETAILS: {
      return {
        ...state,
        tournamentDetails: {},
      }
    }
    case types.SAVE_MY_TOURNAMENT: {
      return {
        ...state,
      }
    }
    case types.UPDATE_MY_TOURNAMENT: {
      return {
        ...state,
      }
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default reducer;

