import tw from "tailwind-styled-components";
import { Text } from '@components';

const CustomButton = ({ title, onClick, style, reversStyle, className, disabled, loading, titleClass }) => {
  const checkOpacity = disabled ? 0.5 : 1;

  const styles = {
    ...style,
    borderRadius: 6,
    opacity: checkOpacity,
  }

  if (!reversStyle) {
    return (
      <Button onClick={onClick} style={styles} className={className} disabled={disabled} loading={loading}>
        {loading && <div className=" w-6 h-6 border-4 border-white/10 border-t-white border-solid rounded-full animate-spin" />}
        <Text className={`lg:py-1 lg:px-2 p-1 px-1 text-[15px] ${titleClass}`}>{title || 'Button Title'}</Text>
      </Button >
    );
  } else {
    return (
      <ReverseButton onClick={onClick} style={styles} className={className} disabled={disabled} loading={loading}>
        {loading && <div className=" w-6 h-6 border-4 border-fbButtonColor/10 border-t-fbButtonColor ml-1 border-solid rounded-full animate-spin" />}
        <Text className={`lg:py-1 lg:px-2 p-1 px-1 text-[15px] ${titleClass}`}>{title || 'Button Title'}</Text>
      </ReverseButton>
    );
  }
}

const Button = tw.button`
${(p) => ((p.disabled || p.loading) ? "cursor-default" : "hover:text-fbButtonColor focus-visible:text-fbButtonColor hover:bg-white focus-visible:bg-white")}
flex
justify-center
items-center
text-white
border-2
border-fbButtonColor
bg-fbButtonColor
transition
delay-75`;

const ReverseButton = tw.button`
${(p) => ((p.disabled || p.loading) ? "cursor-default" : "hover:text-white focus-visible:text-white hover:bg-fbButtonColor focus-visible:bg-fbButtonColor")}
flex
justify-center
items-center
text-fbButtonColor
border-2
border-fbButtonColor
transition
delay-75
bg-white`;

export default CustomButton;